import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getPermissionStatus } from '../../utils/methods';
import { connect } from 'react-redux';
import CompanyEvents from '../../components/CompanyEvents';
import { EventCreate, EventDetails } from '../../components';
import { addCommentOnEvent, addCommentReplyOnEvent, attendEvent, createOndemand, getCommentsOnEvent, getEventAttendees, getEventDetailsById, getOndemandCategory, getOndemandEventDetailsById, getPlacesAction } from '../../redux/actions';
import EventCoachCreate from '../../components/CompanyEvents/EventCoachCreate';
import CreateLunchLearn from '../../components/CreateEvent/createLunchLearn';
import EditLunchAndLearn from '../../components/EditEvent/editLunchAndLearn';
import EditEvent from '../../components/EditEvent/editEvent';
import CreateOndemandMain from '../../components/MainEvents/CreateOndemandClass/createOndemandClass';
import {attendEventLimitUser} from '../../redux/actions/eventActions'

const AdminEvents = ({ history, yearList, selectedYearInfo, companyInfo, role, userPermissions, getPlacesAction, places, isLoading, fetchOndemandCategory, fetchOndemandEventDetailsById, fetchEventDetailsById, networkError, fetchCommentsOnEvent, postCommentOnEvent, postReplyOnComment, replySuccess, profileImage, attendEvent, getEventAttendees, createOndemandEvent, attendEventLimitUser }) => {

  const createEventPermission = getPermissionStatus(
    "Create event",
    userPermissions
  );
  const updateEventPermission = getPermissionStatus(
    "Update event",
    userPermissions
  );

  const addOrRemoveUserPermission = getPermissionStatus(
    "Add or Remove user",
    userPermissions
  );
  const printEventCsvPermission = getPermissionStatus(
    "Print event CSV",
    userPermissions
  );
  
  const CompanyAdminEvents = () => (
    <CompanyEvents
      companyInfo={companyInfo}
      history={history}
      yearList={yearList}
      selectedYearInfo={selectedYearInfo}
      // selectedTabId={1}
      role={role}
      allowedRoles={getPermissionStatus("Create behavior library", userPermissions)}
    />
  );

  const CreateAdminLiveEvent = () => (
    <EventCreate
      getPlacesAction={getPlacesAction}
      places={places}
      history={history}
      parentRoute="/events"
      allowedRoles={createEventPermission}
      userPermissions={userPermissions}
      role={role}
    />
  );

  const EditAdminLiveEvent = () => (
    <EditEvent
      getPlacesAction={getPlacesAction}
      places={places}
      history={history}
      isLoading={isLoading}
      allowedRoles={updateEventPermission}
      userPermissions={userPermissions}
    />
  );

  const CreateAdminOndemandEvent = () => (
    <CreateOndemandMain
      createOndemand={createOndemandEvent}
      history={history}
      allowedRoles={createEventPermission}
      userPermissions={userPermissions}
      fetchOndemandCategory={fetchOndemandCategory}
    />
  );

  const EditAdminOndemandEvent = () => (
    <CreateOndemandMain
      history={history}
      component={CreateOndemandMain}
      parentRoute="/events"
      allowedRoles={updateEventPermission}
      userPermissions={userPermissions}
      fetchOndemandEventDetailsById={fetchOndemandEventDetailsById}
      fetchOndemandCategory={fetchOndemandCategory}
      isEditCategory={1}
    />
  );

  const CreateAdminLunchLearnEvent = () => (
    <CreateLunchLearn
      getPlacesAction={getPlacesAction}
      places={places}
      history={history}
      parentRoute="/company/events"
      allowedRoles={createEventPermission}
      userPermissions={userPermissions}
      role={role}
    />
  );

  const EditAdminLunchLearnEvent = () => (
    <EditLunchAndLearn
      getPlacesAction={getPlacesAction}
      places={places}
      history={history}
      parentRoute="/company/events"
      allowedRoles={updateEventPermission}
      userPermissions={userPermissions}
      role={role}
    />
  )

  const CreateEventCoach = () => (
    <EventCoachCreate
      getPlacesAction={getPlacesAction}
      places={places}
      history={history}
      parentRoute="/company/events"
      allowedRoles={createEventPermission}
      userPermissions={userPermissions}
      role={role}
    />
  );

  const AdminEventDetails = () => (
    <EventDetails
      history={history}
      fetchEventDetailsById={fetchEventDetailsById}
      networkError={networkError}
      fetchCommentsOnEvent={fetchCommentsOnEvent}
      postCommentOnEvent={postCommentOnEvent}
      postReplyOnComment={postReplyOnComment}
      replySuccess={replySuccess}
      profileImage={profileImage}
      attendEvent={attendEvent}
      getEventAttendees={getEventAttendees}
      isAdmin={updateEventPermission}
      addOrRemoveUserPermission={addOrRemoveUserPermission}
      printEventCsvPermission={printEventCsvPermission}
      role={role}
      isAdminEvent={true}
      attendEventLimitUser={attendEventLimitUser}
    />
  )


  return (
    <Switch>
      <Route path="/company/events" render={CompanyAdminEvents} exact={true} />
      <Route path="/company/events/completed" render={CompanyAdminEvents} exact={true} />
      <Route path="/company/events/create" render={CreateAdminLiveEvent} exact={true} />
      <Route path="/company/events/create-coach" render={CreateEventCoach} exact={true} />
      <Route path="/company/events/create-lunch-learns" render={CreateAdminLunchLearnEvent} exact={true} />
      <Route path="/company/events/edit-lunch-learns/:id" render={EditAdminLunchLearnEvent} exact={true} />
      <Route path="/company/events/:id/edit" render={EditAdminLiveEvent} exact={true} />
      <Route path="/company/events/create-ondemand" render={CreateAdminOndemandEvent} exact={true} />
      <Route path="/company/events/edit-ondemand/:id" render={EditAdminOndemandEvent} exact={true} />
      <Route path="/company/events/:id" render={AdminEventDetails} exact={true} />
    </Switch>
  );
};

AdminEvents.propTypes = {
  companies: PropTypes.array,
  companyInfo: PropTypes.object,
  setCompany: PropTypes.func,
  role: PropTypes.string,
  history: PropTypes.object,
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  userPermissions: PropTypes.array,
  getPlacesAction: PropTypes.func.isRequired,
  places: PropTypes.array,
  isLoading: PropTypes.bool,
  fetchOndemandCategory: PropTypes.func,
  fetchOndemandEventDetailsById: PropTypes.func.isRequired,
  fetchEventDetailsById:PropTypes.func,
  networkError: PropTypes.string,
  fetchCommentsOnEvent:PropTypes.func,
  postReplyOnComment:PropTypes.func,
  postCommentOnEvent:PropTypes.func,
  replySuccess: PropTypes.string,
  profileImage: PropTypes.string,
  attendEvent: PropTypes.func,
  getEventAttendees: PropTypes.func,
  createOndemandEvent: PropTypes.func,
  attendEventLimitUser: PropTypes.func
};

const mapStateToProps = (state) => ({
  places: state.events.places,
  isLoading: state.events.isLoading,
  networkError: state.events.networkError,
  replySuccess: state.events.replySuccess,
  profileImage: state.profileData.profileImage,

});

const mapDispatchToProps = (dispatch) => ({
  getPlacesAction: (location) => dispatch(getPlacesAction(location)),
  createOndemandEvent: (eventData) => dispatch(createOndemand(eventData)),
  fetchOndemandCategory: () => dispatch(getOndemandCategory()),
  fetchOndemandEventDetailsById: (id) => dispatch(getOndemandEventDetailsById(id)),
  fetchEventDetailsById: (id) => dispatch(getEventDetailsById(id)),
  fetchCommentsOnEvent: (id) => dispatch(getCommentsOnEvent(id)),
  postCommentOnEvent: (data) => dispatch(addCommentOnEvent(data)),
  postReplyOnComment: (commentId, reply, eventId) => dispatch(addCommentReplyOnEvent(commentId, reply, eventId)),
  attendEvent: (eventId, isAttending, updationList) => dispatch(attendEvent(eventId, isAttending, updationList)),
  getEventAttendees: (eventId) => dispatch(getEventAttendees(eventId)),
  attendEventLimitUser: (eventId, isAttending, callback) => dispatch(attendEventLimitUser(eventId, isAttending, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminEvents);
